import { Component, inject, signal, ViewChild } from '@angular/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { dayjsTimezone } from '../../../core/utils/dayjs-timezone';
import dayjs from 'dayjs';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzCarouselComponent, NzCarouselModule } from 'ng-zorro-antd/carousel';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';

@Component({
  selector: 'app-news',
  standalone: true,
  imports: [
    SvgIconComponent,
    CommonModule,
    RouterModule,
    NzButtonModule,
    NzGridModule,
    NzImageModule,
    NzCardModule,
    NzDividerModule,
    NzModalModule,
    NzCarouselModule,
    NzCollapseModule,
  ],
  templateUrl: './news.component.html',
  styleUrl: './news.component.css',
})
export class NewsComponent {
  showPostModal = signal(false);
  selectedPost = signal<null | number>(0);
  activePost = signal<null | number>(0);
  posts: {
    img: string;
    title: string;
    desc: string;
    date: string;
    subTitle?: string;
    links: { title: string; url: string }[];
  }[] = [
    {
      img: 'assets/images/post/post-1-image.jpg',
      title: 'Kaduna targets N150BN monthly IGR says Budget Commissioner',
      desc: 'The Commissioner of the Kaduna State Planning and Budget Commission, Mukhtar Ahmed, has projected that Kaduna State could generate up to N150 billion in monthly revenue with an improved tax management system facilitated by PayKaduna. Speaking at a stakeholder engagement organized by the Kaduna State Internal Revenue Service (KADIRS), themed “Unlocking Revenue Potential: A Collaborative Strategy for Sustainable Growth through a Centralised Payment Gateway in Kaduna State,” Ahmed emphasized that achieving this goal will depend on the automation of collection processes, strengthened inter-agency collaboration, and widespread taxpayer sensitization.',
      date: '25 Oct 2024',
      links: [
        {
          title: 'Read on Dailytrust',
          url: 'https://dailytrust.com/kaduna-targets-n150bn-monthly-igr',
        },
        {
          title: 'Read on The Nation Online',
          url: 'https://thenationonlineng.net/kaduna-can-generate-n150b-internal-revenue-monthly-says-budget-commissioner/#google_vignette',
        },
        {
          title: 'Read on Gazette',
          url: 'https://gazettengr.com/kaduna-eyes-n150-billion-monthly-revenue-via-tax',
        },
        {
          title: 'Read on Blueprint',
          url: 'https://blueprint.ng/kaduna-eyes-n150bn-monthly-revenue',
        },
        {
          title: 'Watch on Youtube',
          url: 'https://www.youtube.com/watch?v=bmH0oEAqXl4',
        },
      ],
    },
    {
      img: 'assets/images/post/post-2.jpg',
      title: 'KADIRS takes Paykaduna to the Grassroot',
      desc: 'Following the successful launch of the PayKaduna portal, the Kaduna State Internal Revenue Service (KADIRS) has extended its outreach to Zaria and Kafanchan. The initiative aims to engage directly with taxpayers and grassroots stakeholders to explore avenues for enhancing revenue generation across the state. Through this initiative, KADIRS seeks to increase public understanding and participation in the new payment platform, fostering a collaborative approach to sustainable revenue growth',
      date: '31 Oct 2024',
      links: [
        {
          title: 'Read on Twitter',
          url: 'https://x.com/kadirsgovng/status/1852096666519675382',
        },
        {
          title: 'Watch on Youtube',
          url: 'https://youtu.be/_nWjUWeTfkw?si=Vz8-E4Ku3J66xfgb',
        },
      ],
    },
    // {
    //   img: 'assets/images/post/post-4.jpg',
    //   title: 'Stakeholders Meeting',
    //   desc: 'The Education Sub-Committee of Infrastructure Development Council Monitoring',
    //   date: '27 Dec 2020',
    // },
  ];

  posts2 = [
    {
      img: 'assets/images/post/post-6.jpg',
      title: 'Now Is the Time to Think About the Development of the Road',
      desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus lobortis augue condimentum maecenas. Metus at in fames vitae posuere ut vel....',
      date: 'Oct 2024',
    },
    {
      img: 'assets/images/post/post-5.jpg',
      title: 'Education Monitoring',
      desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus lobortis augue condimentum maecenas. Metus at in fames vitae posuere ut vel....',
      date: 'Aug 2023',
    },
    {
      img: 'assets/images/post/post-7.jpg',
      title: 'Stakeholders Meeting',
      desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus lobortis augue condimentum maecenas. Metus at in fames vitae posuere ut vel....',
      date: 'Aug 2023',
    },
  ];

  @ViewChild(NzCarouselComponent, { static: false })
  carousel: NzCarouselComponent;

  goTo(index: number) {
    this.carousel.goTo(index);
  }

  next() {
    console.log(488574857845);
    this.carousel.next();
  }

  prev() {
    this.carousel.pre();
  }

  get dayjs() {
    return dayjs;
  }
}
