<div [ngClass]="{ 'bg-white min-h-[100vh] px-[98px] py-8 space-y-16': true }">
  <div class="flex flex-col justify-center items-center gap-3">
    <!-- <div class="flex justify-center items-center gap-10">
      <p class="text-[16px]">
        Paying of Revenues made easy with Pay Kaduna check out our Tax Trails
      </p>
      <button nz-button nzType="primary">Check out Now</button>
    </div> -->

    <p class="text-[50px] font-bold">
      Pay Kaduna <span class="text-pk-cyan-500">News</span> Portal
    </p>

    <div class="flex gap-4 items-center justify-between">
      <!-- <div class="bg-[#ECFCFF] p-2 rounded-lg flex items-center gap-1">
        <svg-icon
          src="assets/svgs/weather.svg"
          [svgStyle]="{ 'width.px': 20 }"
        ></svg-icon>
        <p class="font-bold tracking-wide text-pk-cyan-500">15&deg;C KADUNA</p>
      </div> -->

      <div class="p-2 flex items-center gap-1">
        <svg-icon
          src="assets/svgs/clock.svg"
          [svgStyle]="{ 'width.px': 20 }"
        ></svg-icon>
        <p class="font-semibold tracking-wide">
          {{ dayjs().format("dddd, DD MMMM YYYY") }}
        </p>
      </div>

      <div class="p-2 flex items-center gap-1">
        <svg-icon
          src="assets/svgs/facebook.svg"
          [svgStyle]="{ 'height.px': 25 }"
        ></svg-icon>
        <svg-icon
          src="assets/svgs/instagram.svg"
          [svgStyle]="{ 'height.px': 25 }"
        ></svg-icon>
        <svg-icon
          src="assets/svgs/youtube.svg"
          [svgStyle]="{ 'height.px': 25 }"
        ></svg-icon>
      </div>
    </div>
  </div>

  <div nz-row nzGutter="30">
    <div nz-col nzSpan="14">
      <div class="h-[466px] relative flex justify-end">
        <nz-carousel
          nzAutoPlay
          [nzAutoPlaySpeed]="5000"
          nzEffect="scrollx"
          [nzLoop]="true"
          (nzAfterChange)="activePost.set($event)"
          class="w-[677px]"
        >
          @for (post of posts; track $index) {
          <div nz-carousel-content class="flex justify-end">
            <img
              nz-image
              [nzSrc]="post.img"
              nzFallback="assets/images/post/fallback.png"
              class="object-cover"
              width="100%"
              nzDisablePreview
              class="rounded-2xl"
            />
          </div>
          }
        </nz-carousel>

        <nz-card class="w-[550px] card opacity-95">
          <div class="space-y-3">
            <p class="text-mute text-base">
              {{ posts[activePost()].date }}
            </p>
            <p class="text-3xl text-title font-bold">
              {{ posts[activePost()].title }}
            </p>
            <p class="text-mute text-base line-clamp-3">
              {{ posts[activePost()].desc }}
            </p>

            <div class="flex justify-between items-center pt-2">
              <button
                nz-button
                nzType="link"
                (click)="
                  selectedPost.set(activePost()); showPostModal.set(true)
                "
              >
                Read More
              </button>

              <div class="space-x-4">
                <button
                  nz-button
                  nzType="primary"
                  (click)="prev()"
                  class="bg-pk-cyan hover:bg-pk-cyan focus:bg-pk-cyan border-0"
                >
                  <svg-icon
                    src="assets/svgs/caret-left.svg"
                    [svgStyle]="{ 'width.px': 20 }"
                  ></svg-icon>
                </button>

                <button
                  nz-button
                  nzType="primary"
                  (click)="next()"
                  class="bg-pk-cyan hover:bg-pk-cyan focus:bg-pk-cyan border-0"
                >
                  <svg-icon
                    src="assets/svgs/caret-right.svg"
                    [svgStyle]="{ 'width.px': 20 }"
                  ></svg-icon>
                </button>
              </div>
            </div>
          </div>
        </nz-card>
      </div>
    </div>

    <div nz-col nzSpan="10" class="space-y-3">
      @for(post of posts; track $index) {
      <nz-card
        nzHoverable
        (click)="selectedPost.set($index); showPostModal.set(true)"
      >
        <div class="flex gap-2 items-center">
          <img
            nz-image
            [nzSrc]="post.img"
            nzFallback="assets/images/post/fallback.png"
            width="250"
            nzDisablePreview
            class="rounded-2xl"
          />

          <div class="space-y-2">
            <p class="text-mute">{{ post.date }}</p>
            <p class="font-bold text-[16px] line-clamp-2">
              {{ post.title }}
            </p>
            <p class="line-clamp-3">{{ post.desc }}</p>
          </div>
        </div>
      </nz-card>
      }
    </div>
  </div>

  <!-- <div nz-row nzGutter="20">
    <p class="font-bold text-2xl cs-underline"><span>Don't Miss</span></p>

    <nz-divider></nz-divider>

    @for(post of posts2; track $index) {
    <div nz-col nzSpan="8" class="flex flex-col gap-2">
      <nz-card
        class="rounded-2xl bg-white shadow-[0px_0px_20px_0px_rgba(6,6,6,0.1)]"
      >
        <img
          nz-image
          [nzSrc]="post.img"
          nzFallback="assets/images/post/fallback.png"
          width="100%"
          style="object-fit: cover; height: 300px"
          class="rounded-xl"
        />

        <div class="space-y-2 mt-4">
          <p class="font-bold text-[16px] min-h-[50px]">{{ post.title }}</p>
          <p>{{ post.desc }}</p>
          <div class="flex justify-between items-center">
            <p>{{ post.date }}</p>
            <button nz-button nzType="link" class="font-semibold">
              Read More
            </button>
          </div>
        </div>
      </nz-card>
    </div>
    }
  </div> -->

  <!-- <div>
    <p class="font-bold text-[16px]">Latest Update</p>

    <nz-divider></nz-divider>

    <div>
      <img
        nz-image
        nzSrc="assets/images/post/post-8.png"
        nzFallback="assets/images/post/fallback.png"
        width="100%"
        style="object-fit: cover"
        class="rounded-xl"
      />
    </div>
  </div> -->
</div>

<nz-modal
  [(nzVisible)]="showPostModal"
  [nzFooter]="null"
  [nzClosable]="false"
  [nzTitle]="null"
  (nzOnCancel)="showPostModal.set(false)"
  nzWidth="650px"
  [nzBodyStyle]="{ paddingTop: '0px' }"
>
  <div *nzModalContent class="flex flex-col gap-4 justify-start items-start">
    <img
      nz-image
      [nzSrc]="posts[selectedPost()]?.img"
      nzFallback="assets/images/post/fallback.png"
      width="100%"
      nzDisablePreview
      class="rounded-2xl"
    />

    <p class="text-2xl font-bold text-title">
      {{ posts[selectedPost()]?.title }}
    </p>

    @if(posts[selectedPost()]?.subTitle) {
    <p class="text-base">{{ posts[selectedPost()]?.subTitle }}</p>
    }

    <p class="text-mute text-xs">
      Published on: {{ posts[selectedPost()]?.date }}
    </p>

    <nz-collapse nzExpandIconPosition="end" nzGhost>
      <nz-collapse-panel [nzHeader]="paymentTitle">
        <ng-template #paymentTitle>
          <button
            nz-button
            nzType="link"
            class="text-pk-cyan hover:text-pk-cyan focus:text-pk-cyan p-0"
          >
            Click here to read full story
          </button>
        </ng-template>

        <div class="flex flex-col items-start border-l">
          @for(link of posts[selectedPost()]?.links; track $index) {
          <a nz-button nzType="link" [href]="link.url" target="_blank">
            {{ link.title }}
          </a>
          }
        </div>
      </nz-collapse-panel>
    </nz-collapse>

    <p class="text-base">{{ posts[selectedPost()]?.desc }}</p>
  </div>
</nz-modal>
